export default{
	inputStyle:{
		labelColor:{color:'#7B848D',fontSize:'28rpx'},
		inputColor:'#333333',
		placeholderStyle:"color:#656869;font-size:28rpx",
		myStyle:"margin-right:16rpx",
		customIconStyle:"margin-right:30rpx",
		fontSize:"28rpx"
	},
	type: {
		10: '运损——外包装损坏，如压坏、挤压、漏洞、变形等',
		20: '缺货——到货件数小于购买件数',
		30: '质量问题——坏果、标品漏坏、商品变质、涨袋、破损、过期等的问题',
		40: '货不对板——到货商品与商品描述不符',
		50: '缺斤少两',
		60: '差价'
	},
	//金额分转元
	formatVal(val) {
		if (val) {
			if ((val * 10000 / 10000 / 100) % 1 === 0) {
				return val * 10000 / 10000 / 100 + '.00'
			} else {
				if ((val * 10000 / 10000 / 100 + '').split('.')[1].length == 1) {
					return val * 10000 / 10000 / 100 + '0'
				} else {
					return val * 10000 / 10000 / 100
				}
			}
		} else {
			return 0
		}
	},
	currenttime() {
		//年
		let year = new Date().getFullYear();
		//月份是从0月开始获取的，所以要+1;
		let month = (new Date().getMonth() +1)>9?(new Date().getMonth() +1):'0'+(new Date().getMonth() +1);
		//日
		let day = (new Date().getDate())>9?new Date().getDate():'0'+(new Date().getDate());
		let time = year.toString() + month.toString() + day.toString()
		return time
	},
	//秒时间戳转时分秒
	MinutetampToTime(timestamp){
		if(timestamp){
			var date = new Date(timestamp);
			var h = (date.getHours()<10 ? ('0'+date.getHours()) : date.getHours()) + ':';
			var m = (date.getMinutes()<10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
			var s = date.getSeconds()<10 ? '0'+date.getSeconds() : date.getSeconds();
			return h+m+s;
		}else{
			return ''
		}

	},
	//时间戳转年月日时分秒
	timestampToTime(timestamp){
		timestamp = timestamp+''
		if(timestamp){
			var date;
			if(timestamp.length==10){
				date = new Date(timestamp * 1000);
			}else{
				date = new Date(timestamp);
			}
			//时间戳为10位需*1000，时间戳为13位的话不需乘1000
			var Y = date.getFullYear() + '-';
			var M = ((date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1)) + '-';
			var D = (date.getDate()<10 ? '0'+date.getDate() : date.getDate()) + ' ';
			var h = (date.getHours()<10 ? ('0'+date.getHours()) : date.getHours()) + ':';
			var m = (date.getMinutes()<10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
			var s = date.getSeconds()<10 ? '0'+date.getSeconds() : date.getSeconds();
			return Y+M+D+h+m+s;
		}else{
			return ''
		}

	},
	toast(msg,status,duration){
		uni.showToast({
			title: msg,
			icon: status?status:'none',
			duration:duration?duration:1500
		});
	},
	addHandle(val,key){
		let total = 0
		for(let i=0;i<val.length;i++){
			total = (total * 10000 + val[i][key]*10000)/10000
		}
		return total
	},
	setTime(timestamp) {
	    let t = timestamp + 24 * 60 * 60 * 1000;   //24小时 * 60分钟 * 60秒 * 1000
	    let d = new Date(t);
	    let theMonth = d.getMonth() + 1;
	    let theDate = d.getDate();
	    let theHours = d.getHours();
	    let theMinutes = d.getMinutes();
	    if (theMonth < 10) {
	      theMonth = '0' + theMonth
	    }
	    if (theDate < 10) {
	      theDate = '0' + theDate
	    }
	    if (theHours < 10) {
	      theHours = '0' + theHours
	    }
	    if (theMinutes < 10) {
	      theMinutes = '0' + theMinutes
	    }
	    let date = d.getFullYear() + '-' + theMonth + '-' + theDate
	    let time = theHours + ':' + theMinutes + ':' + theMinutes
	    let Spare = date + ' ' + time
		return Spare
	  },
	  //千位分隔符
	  numFormat(num){
		if(num){
			num = num.toString().split(".");   //分隔⼩数点
			var arr = num[0].split("").reverse();
			var res = [];
			for(var i=0,len= arr.length;i<len;i++){
			  if(i%3===0&&i!==0){
				res.push(",");
			  }
			  res.push(arr[i]);
			}
			res.reverse();
			if(num[1]){ //如果有⼩数部分的话，添加⼩数部分
				res = res.join("").concat("."+num[1]);
			}else{
				res = res.join("")
			}
			return res
		}else{
			return '0.00'
		}

	  },
	  //标准化金额（.00）
	  toDecimal2(x){
		var f = parseFloat(x);
		if(isNaN(f)){
			return false;
		}
		var f = Math.round(x * 100) / 100;
		var s = f.toString();
		var rs = s.indexOf('.');
		if(rs < 0){
		  rs = s.length;
		  s +='.';
		}
		while(s.length <= rs + 2){
		  s +='0';
		}
		return s
	  },
	  //分转元*100
	  format_money(fen){
		if(fen!=0 && fen!='undefined' && fen!=null && fen!=''){
		  var num = fen;
		  num=fen*100;
		  num+='';
		  var reg = num.indexOf('.')>-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
		  num=num.replace(reg,'$1');
		  num = this.toDecimal2(num)
		  return num
		}
	  },
	  //分转元 /100
	  fen_money(fen){
	  		if(fen!=0 && fen!='undefined' && fen!=null && fen!=''){
	  		  var num = fen;
	  		  num=fen*0.01;
	  		  num+='';
	  		  var reg = num.indexOf('.')>-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
	  		  num=num.replace(reg,'$1');
	  		  num = this.toDecimal2(num)
	  		  return num
	  		}else{
				return '0.00'
			}
	  },
	// 将金额类型转为数字类型
	toNum(str) {
		return str.replace(/\,|\￥/g, "");
	},

	// 保留两位小数（四舍五入）
	toPrice(num) {
		num = parseFloat(this.toNum(num)).toFixed(2).toString().split(".");
		num[0] = num[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)','ig'),"$1,");
		return num.join(".");
	},

	// 保留两位小数（不四舍五入）
	toPrice1(num) {
		num = parseFloat(this.toNum(num).replace(/(\.\d{2})\d+$/,"$1")).toFixed(2).toString().split(".");
		num[0] = num[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)','ig'),"$1,");
		return num.join(".");;
	},

	// 不处理小数部分
	toPrice2(num) {
		var source = this.toNum(num).split(".");
		source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)','ig'),"$1,");
		return source.join(".");
	},

	//不显示数字部分展示*** 或者截取后四位
	toHide(num){
		let newNum = ''
		if(num.length>4){
			// newNum = num.replace(/\s/g, "")
			//     .replace(/(\d{4})\d+(\d{4})$/, "**** **** **** $2");
			newNum = num.substring(num.length-4,num.length)
			  return newNum
		}else{
			return num
		}
	},
	//隐藏部分展示星星
	toStar(){
		let newNum = ''
		if(num.length>4){
			newNum = num.replace(/\s/g, "")
			    .replace(/(\d{4})\d+(\d{4})$/, "**** **** **** $2");
			return newNum
		}else{
			return num
		}
	},
	hideTel(num){
		return num.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
	},
	//标准化时间是否大于9
	timeFormat(time){
		time = time>9?time:'0'+time
		return time
	},
	//深度拷贝
	DeepCopy(target, source){
    for (var k in source) {
      // 获取属性值
      var item = source[k];
      // 判断，数据类型为复杂数据类型Array和Object时，进行递归复制，为简单数据类型时才能进行直接赋值拷贝。
      // 由于Array也属于Object，因此判断时应先判断是否为Array类型再判断是否为Object类型。
      if (item instanceof Array) {
        target[k] = [];
        this.DeepCopy(target[k], item);
      } else if (item instanceof Object) {
        // debugger
        target[k] = {};
        this.DeepCopy(target[k], item);
      } else {
        target[k] = item;
      }
    }
    return target
  },
  /**
   * 匹配过滤非数字内容
   * @params data 数据源
   * @params point 是否保留小数点后两位
  */
  filterUnNumber(data, point = false) {
    data = data?.toString();
    // console.log('🆒 filterUnNumber data', typeof data);
    // const reg = /\d+\.?\d{0,2}/;
    const str = data?.match(/\d+/)?.[0];
    const pointStr = data?.match(/\d+\.?\d{0,2}/)?.[0];
    console.log('🆒 filterUnNumber', data, str, pointStr);
    // return data.replace(/[^\d]/g, '');
    let res = point ? Number(pointStr || 0)  : Number(str || 0);
    return res ? res.toString() : '';
  },
  /**
   * 输出一个（分-秒）的倒计时
   * @param {String|Number} time 原总时间
   * @param {Number} step  间隔多久，非必传
   */
  setCountDown(time = '05:00' || 5 * 60 * 1000, step = 1000) {
    let res = time;
    let total = time;
    if (typeof time === 'string') {
      let list = time.split(':');
      let min = Number(list?.[0]);
      let sec = Number(list?.[1]);
      // 得出剩余毫秒数
      total = (min * 60 + sec) * 1000;
    }
    total = total - step;
    // console.log('🆒 setCountDown', total, step);
    if (total < step) {
      return 0;
    }
    // 输出时间字符串
    let minNext = parseInt(total / 60 / 1000);
    let cruMin = minNext >= 10 ? minNext : '0' + minNext;
    let secNext = parseInt((total % (60 * 1000)) / 1000);
    let cruSec = secNext >= 10 ? secNext : '0' + secNext;
    res = `${cruMin}:${cruSec}`;
    // console.log('🆒 setCountDown', cruMin, cruSec, res);
    return res;
  },
	// 倒计时
	timeDown(endTime){
		//获取时间差
		let now = new Date().getTime()/1000
		let totalSeconds = parseInt((endTime - now));
		//天数
		var days = Math.floor(totalSeconds / (60 * 60 * 24));
		//取模（余数）
		var modulo = totalSeconds % (60 * 60 * 24);
		//小时数
		var hours = Math.floor(modulo / (60 * 60));
		modulo = modulo % (60 * 60);
		//分钟
		var minutes = Math.floor(modulo / 60);
		//秒
		var seconds = modulo % 60;
		//输出还剩多少时间
		return `${days}天 ${hours}小时 ${minutes}分 ${seconds}秒`
	},
	//防止多次提交
	debounce(fuc,delay=500){
		if(time){
			clearTimeout(time);
		}
		time = setTimeout(fuc,delay);
	}
}
